
import http from "./http"
//首页总的动态
export const $apiDynamic = (data) => http({ method: "get", url: "/order/total/float", params: data })
//锁价动态
export const $apiLockDynamic = (data) => http({ method: "get", url: "/order/lock/float", params: data })
//采购动态
export const $apiOrderDynamic = (data) => http({ method: "get", url: "/order/stock/float", params: data })
//询价动态
export const $apiEnquiryDynamic = (data) => http({ method: "get", url: "/order/solicit/float", params: data })
